/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDashboardWidget = /* GraphQL */ `
  mutation CreateDashboardWidget(
    $input: CreateDashboardWidgetInput!
    $condition: ModelDashboardWidgetConditionInput
  ) {
    createDashboardWidget(input: $input, condition: $condition) {
      id
      survey_id
      name
      field
      group_by
      value
      legend
      y_axis_title
      component_type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateDashboardWidget = /* GraphQL */ `
  mutation UpdateDashboardWidget(
    $input: UpdateDashboardWidgetInput!
    $condition: ModelDashboardWidgetConditionInput
  ) {
    updateDashboardWidget(input: $input, condition: $condition) {
      id
      survey_id
      name
      field
      group_by
      value
      legend
      y_axis_title
      component_type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteDashboardWidget = /* GraphQL */ `
  mutation DeleteDashboardWidget(
    $input: DeleteDashboardWidgetInput!
    $condition: ModelDashboardWidgetConditionInput
  ) {
    deleteDashboardWidget(input: $input, condition: $condition) {
      id
      survey_id
      name
      field
      group_by
      value
      legend
      y_axis_title
      component_type
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSurveyUser = /* GraphQL */ `
  mutation CreateSurveyUser(
    $input: CreateSurveyUserInput!
    $condition: ModelSurveyUserConditionInput
  ) {
    createSurveyUser(input: $input, condition: $condition) {
      id
      restricted_field
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSurveyUser = /* GraphQL */ `
  mutation UpdateSurveyUser(
    $input: UpdateSurveyUserInput!
    $condition: ModelSurveyUserConditionInput
  ) {
    updateSurveyUser(input: $input, condition: $condition) {
      id
      restricted_field
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSurveyUser = /* GraphQL */ `
  mutation DeleteSurveyUser(
    $input: DeleteSurveyUserInput!
    $condition: ModelSurveyUserConditionInput
  ) {
    deleteSurveyUser(input: $input, condition: $condition) {
      id
      restricted_field
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey(
    $input: CreateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    createSurvey(input: $input, condition: $condition) {
      id
      survey_content
      survey_name
      sectional
      identifiers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey(
    $input: UpdateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    updateSurvey(input: $input, condition: $condition) {
      id
      survey_content
      survey_name
      sectional
      identifiers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSurvey = /* GraphQL */ `
  mutation DeleteSurvey(
    $input: DeleteSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    deleteSurvey(input: $input, condition: $condition) {
      id
      survey_content
      survey_name
      sectional
      identifiers
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSurveySection = /* GraphQL */ `
  mutation CreateSurveySection(
    $input: CreateSurveySectionInput!
    $condition: ModelSurveySectionConditionInput
  ) {
    createSurveySection(input: $input, condition: $condition) {
      id
      survey_id
      section_id
      section_content
      ordering
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSurveySection = /* GraphQL */ `
  mutation UpdateSurveySection(
    $input: UpdateSurveySectionInput!
    $condition: ModelSurveySectionConditionInput
  ) {
    updateSurveySection(input: $input, condition: $condition) {
      id
      survey_id
      section_id
      section_content
      ordering
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSurveySection = /* GraphQL */ `
  mutation DeleteSurveySection(
    $input: DeleteSurveySectionInput!
    $condition: ModelSurveySectionConditionInput
  ) {
    deleteSurveySection(input: $input, condition: $condition) {
      id
      survey_id
      section_id
      section_content
      ordering
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSurveyResult = /* GraphQL */ `
  mutation CreateSurveyResult(
    $input: CreateSurveyResultInput!
    $condition: ModelSurveyResultConditionInput
  ) {
    createSurveyResult(input: $input, condition: $condition) {
      owner
      id
      survey_result
      mode
      survey_detail
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      updated_at
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSurveyResult = /* GraphQL */ `
  mutation UpdateSurveyResult(
    $input: UpdateSurveyResultInput!
    $condition: ModelSurveyResultConditionInput
  ) {
    updateSurveyResult(input: $input, condition: $condition) {
      owner
      id
      survey_result
      mode
      survey_detail
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      updated_at
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSurveyResult = /* GraphQL */ `
  mutation DeleteSurveyResult(
    $input: DeleteSurveyResultInput!
    $condition: ModelSurveyResultConditionInput
  ) {
    deleteSurveyResult(input: $input, condition: $condition) {
      owner
      id
      survey_result
      mode
      survey_detail
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      updated_at
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      survey {
        id
        survey_content
        survey_name
        sectional
        identifiers
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      email
      avatarKey
      description
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      avatarKey
      description
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      email
      avatarKey
      description
      owner
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSurveyResultArchive = /* GraphQL */ `
  mutation CreateSurveyResultArchive(
    $input: CreateSurveyResultArchiveInput!
    $condition: ModelSurveyResultArchiveConditionInput
  ) {
    createSurveyResultArchive(input: $input, condition: $condition) {
      id
      survey_id
      survey_result_id
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      submittedAt
      completedAt
      createdAt
      updatedAt
      surveyResultArchiveUserId
      mode
      owner
      your_house
      sanitation_facility
      electricity_in_your_house
      primary_normal_season
      employment_primary_job
      are_you_a_samurdhi_beneficiary
      younger_fifteen_year_dependents
      disabled_dependents
      elder_dependents
      are_you_receiving_public_monthly_assistance_currently
      highest_education_head_household
      value_of_the_samurdhi_stamp
      family_members_receiving_family_public_assistance_total
      family_public_assistance_elders_assistance
      family_public_assistance_disability_assistance
      family_public_assistance_assistance_disease
      family_public_assistance_kidney
      family_public_assistance_tb
      family_public_assistance_leprosy
      family_public_assistance_cancer
      family_public_assistance_spinal_code_injury
      total_income_family
      age_head_household
      no_of_dependent
      sex_of_head_household
      widowed_reason
      do_you_have_own_land
      do_you_have_own_house
      head_household_disabled
      head_of_household
      interest_self_employment
      access_to_well
      count
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateSurveyResultArchive = /* GraphQL */ `
  mutation UpdateSurveyResultArchive(
    $input: UpdateSurveyResultArchiveInput!
    $condition: ModelSurveyResultArchiveConditionInput
  ) {
    updateSurveyResultArchive(input: $input, condition: $condition) {
      id
      survey_id
      survey_result_id
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      submittedAt
      completedAt
      createdAt
      updatedAt
      surveyResultArchiveUserId
      mode
      owner
      your_house
      sanitation_facility
      electricity_in_your_house
      primary_normal_season
      employment_primary_job
      are_you_a_samurdhi_beneficiary
      younger_fifteen_year_dependents
      disabled_dependents
      elder_dependents
      are_you_receiving_public_monthly_assistance_currently
      highest_education_head_household
      value_of_the_samurdhi_stamp
      family_members_receiving_family_public_assistance_total
      family_public_assistance_elders_assistance
      family_public_assistance_disability_assistance
      family_public_assistance_assistance_disease
      family_public_assistance_kidney
      family_public_assistance_tb
      family_public_assistance_leprosy
      family_public_assistance_cancer
      family_public_assistance_spinal_code_injury
      total_income_family
      age_head_household
      no_of_dependent
      sex_of_head_household
      widowed_reason
      do_you_have_own_land
      do_you_have_own_house
      head_household_disabled
      head_of_household
      interest_self_employment
      access_to_well
      count
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteSurveyResultArchive = /* GraphQL */ `
  mutation DeleteSurveyResultArchive(
    $input: DeleteSurveyResultArchiveInput!
    $condition: ModelSurveyResultArchiveConditionInput
  ) {
    deleteSurveyResultArchive(input: $input, condition: $condition) {
      id
      survey_id
      survey_result_id
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      submittedAt
      completedAt
      createdAt
      updatedAt
      surveyResultArchiveUserId
      mode
      owner
      your_house
      sanitation_facility
      electricity_in_your_house
      primary_normal_season
      employment_primary_job
      are_you_a_samurdhi_beneficiary
      younger_fifteen_year_dependents
      disabled_dependents
      elder_dependents
      are_you_receiving_public_monthly_assistance_currently
      highest_education_head_household
      value_of_the_samurdhi_stamp
      family_members_receiving_family_public_assistance_total
      family_public_assistance_elders_assistance
      family_public_assistance_disability_assistance
      family_public_assistance_assistance_disease
      family_public_assistance_kidney
      family_public_assistance_tb
      family_public_assistance_leprosy
      family_public_assistance_cancer
      family_public_assistance_spinal_code_injury
      total_income_family
      age_head_household
      no_of_dependent
      sex_of_head_household
      widowed_reason
      do_you_have_own_land
      do_you_have_own_house
      head_household_disabled
      head_of_household
      interest_self_employment
      access_to_well
      count
      _version
      _deleted
      _lastChangedAt
      user {
        id
        username
        email
        avatarKey
        description
        owner
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSurveyAnalysisConfiguration = /* GraphQL */ `
  mutation CreateSurveyAnalysisConfiguration(
    $input: CreateSurveyAnalysisConfigurationInput!
    $condition: ModelSurveyAnalysisConfigurationConditionInput
  ) {
    createSurveyAnalysisConfiguration(input: $input, condition: $condition) {
      id
      survey_id
      metric_name
      title
      execution_frequency
      group_by
      measured_field
      measured_value
      measured_against_field
      measured_against_value
      measured_against_fields
      measured_against_values
      multi_field_mode
      measurement_type
      next_update
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSurveyAnalysisConfiguration = /* GraphQL */ `
  mutation UpdateSurveyAnalysisConfiguration(
    $input: UpdateSurveyAnalysisConfigurationInput!
    $condition: ModelSurveyAnalysisConfigurationConditionInput
  ) {
    updateSurveyAnalysisConfiguration(input: $input, condition: $condition) {
      id
      survey_id
      metric_name
      title
      execution_frequency
      group_by
      measured_field
      measured_value
      measured_against_field
      measured_against_value
      measured_against_fields
      measured_against_values
      multi_field_mode
      measurement_type
      next_update
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSurveyAnalysisConfiguration = /* GraphQL */ `
  mutation DeleteSurveyAnalysisConfiguration(
    $input: DeleteSurveyAnalysisConfigurationInput!
    $condition: ModelSurveyAnalysisConfigurationConditionInput
  ) {
    deleteSurveyAnalysisConfiguration(input: $input, condition: $condition) {
      id
      survey_id
      metric_name
      title
      execution_frequency
      group_by
      measured_field
      measured_value
      measured_against_field
      measured_against_value
      measured_against_fields
      measured_against_values
      multi_field_mode
      measurement_type
      next_update
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSurveyAnalysisResult = /* GraphQL */ `
  mutation CreateSurveyAnalysisResult(
    $input: CreateSurveyAnalysisResultInput!
    $condition: ModelSurveyAnalysisResultConditionInput
  ) {
    createSurveyAnalysisResult(input: $input, condition: $condition) {
      id
      survey_id
      metric_name
      title
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      execution_frequency
      group_by
      measured_field
      measured_value
      measured_against_field
      measured_against_value
      measured_against_fields
      measured_against_values
      multi_field_mode
      measurement_type
      next_update
      final_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateSurveyAnalysisResult = /* GraphQL */ `
  mutation UpdateSurveyAnalysisResult(
    $input: UpdateSurveyAnalysisResultInput!
    $condition: ModelSurveyAnalysisResultConditionInput
  ) {
    updateSurveyAnalysisResult(input: $input, condition: $condition) {
      id
      survey_id
      metric_name
      title
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      execution_frequency
      group_by
      measured_field
      measured_value
      measured_against_field
      measured_against_value
      measured_against_fields
      measured_against_values
      multi_field_mode
      measurement_type
      next_update
      final_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteSurveyAnalysisResult = /* GraphQL */ `
  mutation DeleteSurveyAnalysisResult(
    $input: DeleteSurveyAnalysisResultInput!
    $condition: ModelSurveyAnalysisResultConditionInput
  ) {
    deleteSurveyAnalysisResult(input: $input, condition: $condition) {
      id
      survey_id
      metric_name
      title
      district
      divisional_secretariat
      gn_division
      district_
      divisional_secretariat_
      gn_division_
      execution_frequency
      group_by
      measured_field
      measured_value
      measured_against_field
      measured_against_value
      measured_against_fields
      measured_against_values
      multi_field_mode
      measurement_type
      next_update
      final_value
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createSurveyExport = /* GraphQL */ `
  mutation CreateSurveyExport(
    $input: CreateSurveyExportInput!
    $condition: ModelSurveyExportConditionInput
  ) {
    createSurveyExport(input: $input, condition: $condition) {
      id
      survey_id
      export_id
      exported_at
      initial_date
      last_date
      custom_filter_index
      custom_filter_field
      custom_filter_value
      s3_link
      status
      filters
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSurveyExport = /* GraphQL */ `
  mutation UpdateSurveyExport(
    $input: UpdateSurveyExportInput!
    $condition: ModelSurveyExportConditionInput
  ) {
    updateSurveyExport(input: $input, condition: $condition) {
      id
      survey_id
      export_id
      exported_at
      initial_date
      last_date
      custom_filter_index
      custom_filter_field
      custom_filter_value
      s3_link
      status
      filters
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSurveyExport = /* GraphQL */ `
  mutation DeleteSurveyExport(
    $input: DeleteSurveyExportInput!
    $condition: ModelSurveyExportConditionInput
  ) {
    deleteSurveyExport(input: $input, condition: $condition) {
      id
      survey_id
      export_id
      exported_at
      initial_date
      last_date
      custom_filter_index
      custom_filter_field
      custom_filter_value
      s3_link
      status
      filters
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
