<template>
  <div class="flex flex-col flex-1">
    <Topbar :pages="pages"></Topbar>
    <!-- <button @click="createSurvey">create</button> -->
    <div class="flex flex-col p-6 flex-1">
      <ResourceTable
        :disableActions="false"
        name="SurveysTable"
        :variables="{}"
        :columns="columns"
        :page="1"
        queryKey="listSurveys"
        :query="ResultQuery"
      >
        <template v-slot:default="slotProps">
          <button
            type="button"
            @click="onView(slotProps.item)"
            class="mx-4 px-2 py-2 text-left bg-gray-600 text-white"
          >
            View
          </button>
<!--          <button-->
<!--            v-if="userGroup.includes('Super')"-->
<!--            type="button"-->
<!--            @click="onEdit(slotProps.item)"-->
<!--            class="mx-4 px-2 py-2 text-left bg-blue-900 text-white"-->
<!--          >-->
<!--            Edit-->
<!--          </button>-->
        </template>
      </ResourceTable>
    </div>
  </div>
</template>

<script>
import ResourceTable from '@/components/ResourceTable';
import moment from 'moment-timezone';
import {listSurveys} from '../../graphql/queries';
import {v4 as uuidv4} from 'uuid';
import {API, graphqlOperation} from '@aws-amplify/api';
import {createSurvey} from '../../graphql/mutations';
import {mapGetters} from 'vuex';

const columns = [
  {
    name: 'survey_name',
    text: 'Name',
    render: (item) => item?.survey_name,
  },
  {
    name: 'updated',
    text: 'Updated',
    render: (item) => moment(item?._lastChangedAt).toString(),
  },
];

export default {
  components: {
    ResourceTable,
  },
  data() {
    return {userGroup: null};
  },
  computed: {
    ...mapGetters({
      group: 'app/getGroup',
      groups: 'app/groups',
    }),
    ResultQuery() {
      return listSurveys;
    },
    columns() {
      return columns;
    },
    pages() {
      return [
        {
          name: 'Surveys',
          to: {name: 'surveys'},
          current: true,
        },
      ];
    },
  },
  async created() {
    this.userGroup = await this.group;
  },
  methods: {
    async createSurvey() {
      const CCS = {
        id: uuidv4(),
        survey_name: 'New Survey',
      };
      await API.graphql(graphqlOperation(createSurvey, {input: CCS})).then(() => {});
    },
    onView(item) {
      this.$router.push({
        name: 'surveys.results',
        params: {id: item.id},
      });
    },
    onEdit(item) {
      this.$router.push({
        name: 'surveys.edit',
        params: {id: item.id},
      });
    },
  },
  mounted() {},
};
</script>
